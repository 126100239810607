import {withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

export default () => (
    <Layout
        title="Kwetsbaarheid & Veiligheid"
        description="Kwetsbaarheid gevonden in een van onze softwareproducten? Hier staat duidelijk wat we doen bij de melding van een veiligheidsprobleem."
        ogImage="veiligheid.png"
        translatedPagePath="/en/terms-and-conditions/security/">
        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            Veiligheid <br />& Kwetsbaarheid
                        </h2>
                        <div className="display__number">VK</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Bij Label305 vinden we de veiligheid van de door ons ontwikkelde softwareproducten en onze{' '}
                            eigen systemen erg belangrijk. Ondanks onze zorg voor de beveiliging kan het voorkomen dat{' '}
                            er toch een kwetsbaarheid aanwezig is.
                        </p>
                        <p>
                            Als je een kwetsbaarheid in één van onze eigen systemen hebt gevonden horen wij dit graag{' '}
                            zodat we <span className="highlight">zo snel mogelijk</span> maatregelen kunnen treffen.
                        </p>
                        <p>
                            Heb je een kwetsbaarheid in één van de door ons ontwikkelde softwareproducten gevonden? Ook{' '}
                            dan horen wij dit graag zodat we hiermee snel aan de slag kunnen gaan. We kunnen helaas niet{' '}
                            voor onze opdrachtgever spreken, controleer daarom ook eerst het ontsluitingsbeleid van het{' '}
                            softwareproduct zelf.
                        </p>
                        <p>
                            Wij streven ernaar alle problemen zo snel mogelijk op te lossen en we worden graag betrokken{' '}
                            bij een eventuele publicatie over het probleem nadat het is opgelost.
                        </p>
                        <p>
                            <u>
                                Wegens de vele meldingen afgelopen jaren van triviale of niet uit te buiten
                                kwetsbaarheden en geaccepteerde risico's geven we{' '}
                                <span className="highlight">geen</span> beloningen meer.
                            </u>
                        </p>
                    </div>

                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="mailto:security@305.nl"
                            name="security@305.nl"
                            title="E-mail ons met een veiligheidsmelding"
                        />
                    </div>
                </div>
            </article>
        </section>
    </Layout>
);
